.user__container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user__panel{
    margin: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.user__panel button{
    width: 350px;
}

