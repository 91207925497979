* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

body{
  background-color: #121212;
  font-family: 'Roboto', sans-serif;
}

.faq__container{
  width: 80%;
  margin: 0 auto;
}

.carousel__container{
  padding: 100px 200px;
}

.app__container{
  min-height: calc(100vh - 400px);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition-delay: 99999s;
    transition-delay: 99999s;
}
input:-webkit-autofill::first-line,
input:-webkit-autofill:hover::first-line,
input:-webkit-autofill:focus::first-line,
input:-webkit-autofill:active::first-line {
    font-family: 'Your-Font-Family';
    font-size: 16px; 
    -webkit-text-fill-color: #BB86FC !important;
    color: #BB86FC !important;
    box-shadow: 0 0 0px 1000px rgba(20, 20, 20, 0.95) inset;
    -webkit-box-shadow: 0 0 0px 1000px rgba(20, 20, 20, 0.95) inset;
}

@media screen and (max-width: 960px) {
  .carousel__container{
    padding: 100px 0px;
  }
}