.admin__container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin__panel{
    margin: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin__panel button{
    width: 350px;
}

.send__cash, .char__identif, .ban__account, .account__identif{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    text-align: center;
    padding: 10px;
    margin: 2%;
}

.send__cash *, .char__identif *, .ban__account *, .account__identif *{
    padding: 10px;
    margin: 5px;
}

.send__mail {
    width: 70%;
    margin: auto;
    margin-top: 1%;
    padding: 2em;
    background-color: #222;
    color: #ddd;
    box-shadow: 0px 8px 16px 0px rgba(255,255,255,0.2);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.send__mail h3 {
    margin-bottom: 1em;
    color: #BB86FC;
}
  
.send__mail .MuiTextField-root {
    margin-bottom: 1em;
    color: #ddd;
    width: 100%;
}
  
.send__mail .MuiFormLabel-root {
    color: #ddd;
}
  
.send__mail .MuiInputBase-input {
    color: #ddd;
}
  
.send__mail .MuiButton-root {
    margin-right: 1em;
    margin-bottom: 1em;
    background-color: #BB86FC;
    color: #222;
}
  
.send__mail p {
    color: red;
    font-weight: bold;
}
  
.item-form {
    background-color: #333;
    padding: 1em;
    margin-bottom: 1em;
    border-radius: 5px;
    width: 100%;
}
  
.item-form .MuiTextField-root {
    margin-bottom: 1em;
    color: #ddd;
    width: 100%;
}
  
.item-form .MuiFormLabel-root {
    color: #ddd;
}
  
.item-form .MuiInputBase-input {
    color: #ddd;
}
  
.item-form .MuiButton-root {
    background-color: #BB86FC;
    color: #222;
}

.send__mail-title {
    color: #fff;
    font-size: 24px;
    margin-bottom: 1em;
}

.send__rewards {
    width: 70%;
    margin: auto;
    margin-top: 1%;
    padding: 2em;
    background-color: #222;
    color: #ddd;
    box-shadow: 0px 8px 16px 0px rgba(255,255,255,0.2);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.send__rewards h3 {
    margin-bottom: 1em;
    color: #BB86FC;
}
  
.send__rewards .MuiTextField-root {
    margin-bottom: 1em;
    color: #ddd;
    width: 100%;
}
  
.send__rewards .MuiFormLabel-root {
    color: #ddd;
}
  
.send__rewards .MuiInputBase-input {
    color: #ddd;
}
  
.send__rewards .MuiButton-root {
    margin-right: 1em;
    margin-bottom: 1em;
    background-color: #BB86FC;
    color: #222;
}
  
.send__rewards p {
    color: red;
    font-weight: bold;
}
  
.reward-item-form {
    background-color: #333;
    padding: 1em;
    margin-bottom: 1em;
    border-radius: 5px;
    width: 100%;
}
  
.reward-item-form .MuiTextField-root {
    margin-bottom: 1em;
    color: #ddd;
    width: 100%;
}
  
.reward-item-form .MuiFormLabel-root {
    color: #ddd;
}
  
.reward-item-form .MuiInputBase-input {
    color: #ddd;
}
  
.reward-item-form .MuiButton-root {
    background-color: #BB86FC;
    color: #222;
}

.send__rewards-title {
    color: #fff;
    font-size: 24px;
    margin-bottom: 1em;
}
