.navbar {
    background: rgba(20, 20, 20, 0.95);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0 20px;
}
  
  .container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
  }
  
  .navbar-logo {
    justify-self: start;
    cursor: pointer;
    height: 50px;
    width: 50px;
  }

  .navbar-icon, .fa-bars, .fa-times, .RiAdminLine, .RiUserSettingsLine {
    font-size: 1.5rem;
    color: #ffbb00;
  }

  .navbar-icon {
    margin-right: 0.5rem;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: flex-end;
  }
  
  .nav-item {
    height: 55px;
    border-bottom: 2px solid transparent;
  }
  
  .nav-item:hover {
    border-bottom: 2px solid #ffbb00;
  }
  
  .nav-links {
    color: #d6a316;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    color: #ffbb00;
    transform: scale(1.05);
    transition: all 0.3s ease;
  }
  
  .menu-icon {
    display: none;
    align-items: center;
    justify-content: center; 
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: rgba(20, 20, 20, 0.95);
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
      display: flex;
      justify-content: flex-start;
      padding: 10px 0;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
    }
  
    .nav-links:hover {
      color: #ffbb00;
      transform: scale(1.05);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 40%);
      font-size: 1.8rem;
      color: #ffbb00; 
      cursor: pointer;
    }

    .nav-links, .nav-item, .nav-btn {
      margin: 10px 0;
    }
    
    .nav-btn {
      color: #ffbb00;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      padding: 10px 20px;
    }

    .the-blur.active{
      top:0;
      width: 100%;
      height: 100%;
      position: fixed;
      background: rgba(0, 0, 0, 0.247);
      z-index:998;
      backdrop-filter: blur(10px) saturate(125%);
      -webkit-backdrop-filter: blur(10px) saturate(125%);
   }
  }

.the-blur{
    top:0;
    width: 100%;
    height: 80px;
    position: fixed;
    background: rgba(0, 0, 0, 0.247);
    z-index:998;
    backdrop-filter: blur(10px) saturate(125%);
    -webkit-backdrop-filter: blur(10px) saturate(125%);
 }

 .time__container{
   display: flex;
   justify-content: center;
   align-items: center;
   color: #ffbb00;
   height: 100%;
   width: 100%;
 }

 .navbar__left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
 }
 .time__title{
  width: 100%;
  text-align: center;
  color: #ffbb00;
  font-size: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 }